import React from 'react';
import { Typography, Box, Container, IconButton, Divider } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import MetaTags from './MetaTags';

function Contact() {
  return (
    <>
      <MetaTags 
        title="Contact Us" 
        description="Get in touch with the SoozHub team for inquiries about our cryptocurrency analytics platform, or connect with us on social media."
      />
      <Container maxWidth="md">
        <Box sx={{ my: 4, textAlign: 'center' }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Contact Us
          </Typography>
          <Divider sx={{ my: 2, borderColor: 'primary.main', width: '50%', margin: 'auto' }} />
          
          <Box sx={{ my: 4, px: 3, py: 3, backgroundColor: 'background.paper', borderRadius: 2, boxShadow: 3 }}>
            <Typography variant="h6" gutterBottom>
              Business Inquiries
            </Typography>
            <Typography variant="body1" paragraph>
              For business inquiries or to contact the team:
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
              <IconButton aria-label="Email" color="primary" href="mailto:soozzarsoftware@gmail.com">
                <EmailIcon />
              </IconButton>
              <Typography variant="body1">
                soozzarsoftware@gmail.com
              </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <IconButton aria-label="X" color="primary" href="https://x.com/SoozHub">
                <XIcon />
              </IconButton>
              <IconButton aria-label="GitHub" color="primary" href="https://github.com/SoozHub">
                <GitHubIcon />
              </IconButton>
            </Box>
          </Box>

          <Divider sx={{ my: 2, borderColor: 'primary.main', width: '50%', margin: 'auto' }} />
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
            Contact the Founder
          </Typography>
          <Typography variant="h6">Carlos Mastrangelo</Typography>
          <Box sx={{ mt: 2 }}>
            <IconButton aria-label="X" color="primary" href="https://x.com/itsaiwa">
              <XIcon />
            </IconButton>
            <IconButton aria-label="GitHub" color="primary" href="https://github.com/cmastrangelo">
              <GitHubIcon />
            </IconButton>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default Contact;