import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

/**
 * MetaTags component for setting page-specific metadata
 * @param {Object} props - Component props
 * @param {string} props.title - Page title
 * @param {string} props.description - Meta description for the page
 * @param {string} [props.canonicalUrl] - Optional canonical URL (will override the default)
 * @param {Object} [props.additionalMetaTags] - Optional additional meta tags
 */
const MetaTags = ({ 
  title, 
  description, 
  canonicalUrl, 
  additionalMetaTags = {}
}) => {
  // Base site title and description for fallbacks
  const baseSiteTitle = 'SoozHub';
  const baseSiteDescription = 'SoozHub - Cryptocurrency analytics and algorithmic trading platform';
  
  // SoozHub first, then page name
  const pageTitle = title ? `${baseSiteTitle} | ${title}` : baseSiteTitle;
  const pageDescription = description || baseSiteDescription;
  
  // Get current path for canonical URL
  const location = useLocation();
  const path = location.pathname;
  
  // Use provided canonical URL or generate default one
  const defaultCanonicalUrl = `https://soozhub.com${path}`;
  const finalCanonicalUrl = canonicalUrl || defaultCanonicalUrl;
  
  return (
    <Helmet>
      {/* Basic Metadata */}
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      
      {/* Canonical URL - always included */}
      <link rel="canonical" href={finalCanonicalUrl} />
      
      {/* Additional Meta Tags */}
      {Object.entries(additionalMetaTags).map(([name, content]) => (
        <meta key={name} name={name} content={content} />
      ))}
    </Helmet>
  );
};

export default MetaTags; 