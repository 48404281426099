import React, { useState, useEffect } from 'react';
import { Box, Paper, CircularProgress } from '@mui/material';
import { Chart } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    LineController,
    BarController
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import axios from 'axios';
import ControlPanel from './ControlPanel';
import MetaTags from '../../../components/MetaTags';

// Register ChartJS components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    LineController,
    BarController
);

const MarketData = ({ apiUrl, setNavbarOptions }) => {
    const [priceData, setPriceData] = useState([]);
    const [volumeData, setVolumeData] = useState([]);
    const [timeframe, setTimeframe] = useState('1m');
    const [symbol, setSymbol] = useState('BTC');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, [timeframe, symbol]);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const [priceResponse, volumeResponse] = await Promise.all([
                axios.get(`${apiUrl}/dashboard/market-data/price/?timeframe=${timeframe}&symbol=${symbol}`),
                axios.get(`${apiUrl}/dashboard/market-data/volume/?timeframe=${timeframe}&symbol=${symbol}`)
            ]);

            setPriceData(priceResponse.data);
            setVolumeData(volumeResponse.data);
        } catch (error) {
            console.error('Error fetching market data:', error);
            console.error('Error details:', error.response?.data);
        }
        setIsLoading(false);
    };

    const formatNumber = (num) => {
        if (num >= 1e9) return (num / 1e9).toFixed(2) + 'B';
        if (num >= 1e6) return (num / 1e6).toFixed(2) + 'M';
        if (num >= 1e3) return (num / 1e3).toFixed(2) + 'K';
        return num.toFixed(2);
    };

    // Calculate min and max values for better scaling
    const maxVolume = Math.max(...volumeData.map(d => d.volume_24h));
    const minPrice = Math.min(...priceData.map(d => d.price));
    const maxPrice = Math.max(...priceData.map(d => d.price));
    const priceRange = maxPrice - minPrice;

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        if (timeframe === '1d') {
            return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        } else {
            return date.toLocaleDateString([], { month: 'numeric', day: 'numeric' });
        }
    };

    const combinedChartData = {
        labels: priceData.map(d => new Date(d.datetime).getTime()),
        datasets: [
            {
                label: 'Price',
                data: priceData.map(d => ({
                    x: new Date(d.datetime).getTime(),
                    y: d.price
                })),
                type: 'line',
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
                yAxisID: 'y',
                order: 1,
                pointRadius: 0,
                borderWidth: 2
            },
            {
                label: 'Volume',
                data: volumeData.map(d => ({
                    x: new Date(d.datetime).getTime(),
                    y: d.volume_24h
                })),
                type: 'bar',
                backgroundColor: 'rgba(153, 102, 255, 0.2)',
                borderColor: 'rgba(153, 102, 255, 0.8)',
                borderWidth: 1,
                yAxisID: 'y1',
                order: 2,
            }
        ]
    };

    // Get min and max dates from the data
    const dates = priceData.map(d => new Date(d.datetime).getTime());
    const minDate = Math.min(...dates);
    const maxDate = Math.max(...dates);

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: '#d9d9d9',
                    usePointStyle: true,
                    pointStyle: function(context) {
                        return context.datasetIndex === 0 ? 'line' : 'rect';
                    }
                },
                align: 'center'
            },
            title: {
                display: true,
                text: `${symbol} Market Data`,
                color: '#d9d9d9'
            },
            tooltip: {
                callbacks: {
                    title: function(tooltipItems) {
                        const date = new Date(tooltipItems[0].parsed.x);
                        if (timeframe === '1d') {
                            return date.toLocaleTimeString([], { 
                                hour: '2-digit', 
                                minute: '2-digit',
                                second: '2-digit'
                            });
                        } else {
                            return date.toLocaleDateString([], { 
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                            });
                        }
                    },
                    label: function(context) {
                        if (context.dataset.yAxisID === 'y') {
                            return 'Price: $' + formatNumber(context.parsed.y);
                        } else if (context.dataset.yAxisID === 'y1') {
                            return 'Volume: $' + formatNumber(context.parsed.y);
                        }
                        return null;
                    }
                },
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                titleColor: '#d9d9d9',
                bodyColor: '#d9d9d9',
                borderColor: '#6c757d',
                borderWidth: 1
            }
        },
        layout: {
            padding: {
                right: 10,
                left: 10
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: timeframe === '1d' ? 'hour' : 'day',
                    displayFormats: {
                        hour: 'HH:mm',
                        day: 'MMM d'
                    }
                },
                display: true,
                grid: {
                    display: false,
                    color: '#444444'
                },
                ticks: {
                    color: '#d9d9d9',
                    maxRotation: 0,
                    autoSkip: true,
                    maxTicksLimit: timeframe === '1d' ? 6 : 7,
                    callback: function(value, index, values) {
                        const date = new Date(value);
                        if (timeframe === '1d') {
                            return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
                        } else {
                            return date.toLocaleDateString([], { month: 'numeric', day: 'numeric' });
                        }
                    }
                },
                min: minDate,
                max: maxDate,
                bounds: 'data',
                offset: false,
                distribution: 'linear'
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Price ($)',
                    color: '#d9d9d9'
                },
                ticks: {
                    callback: function(value) {
                        return '$' + formatNumber(value);
                    },
                    color: '#d9d9d9',
                    maxTicksLimit: 6,
                    includeBounds: true,
                    padding: 10
                },
                grid: {
                    color: '#444444',
                    drawBorder: false
                },
                border: {
                    display: false
                },
                min: minPrice - (priceRange * 0.05),
                max: maxPrice + (priceRange * 0.05)
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                    display: false
                },
                max: maxVolume * 5,
                min: 0,
                beginAtZero: true,
                ticks: {
                    display: false
                },
                border: {
                    display: false
                },
                afterFit: function(scaleInstance) {
                    scaleInstance.width = 0;
                }
            }
        }
    };

    return (
        <>
            <MetaTags 
                title="Market Data" 
                description="Analyze real-time cryptocurrency market data, price trends, and trading volume with SoozHub's comprehensive interactive charts and analytics tools."
            />
            <Box sx={{ p: 3 }}>
                <ControlPanel
                    timeframe={timeframe}
                    setTimeframe={setTimeframe}
                    symbol={symbol}
                    setSymbol={setSymbol}
                />
                <Paper sx={{ 
                    p: 2, 
                    height: '600px', 
                    backgroundColor: 'transparent',
                    '& canvas': {
                        backgroundColor: 'transparent'
                    },
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {isLoading ? (
                        <CircularProgress sx={{ color: 'primary.main' }} />
                    ) : (
                        <Chart type="bar" data={combinedChartData} options={chartOptions} />
                    )}
                </Paper>
            </Box>
        </>
    );
};

export default MarketData; 