import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import PublicIcon from '@mui/icons-material/Public';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CollectionsIcon from '@mui/icons-material/Collections';
import ChatIcon from '@mui/icons-material/Chat';
import HistoryIcon from '@mui/icons-material/History';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import TimelineIcon from '@mui/icons-material/Timeline';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DashboardIcon from '@mui/icons-material/Dashboard';

export const dashboardNavbarOptions = {
    title: 'Dashboard',
    sections: [
        {
            title: 'Overview',
            items: [
                {
                    id: 'dashboard',
                    label: 'Dashboard',
                    icon: <DashboardIcon />,
                    path: '/'
                }
            ]
        },
        {
            title: 'Cryptocurrency',
            items: [
                { 
                    id: 'market-data', 
                    label: 'Market Data', 
                    icon: <TrendingUpIcon />, 
                    path: '/market-data'
                },
                { 
                    id: 'reddit-sentiment', 
                    label: 'Sentiment', 
                    icon: <SentimentSatisfiedAltIcon />, 
                    path: '/sentiment'
                },
                { 
                    id: 'nfts', 
                    label: 'NFTs', 
                    icon: <CollectionsIcon />, 
                    path: '/nfts'
                },
                {
                    id: 'halving-returns',
                    label: 'Halving Cycle Analysis',
                    icon: <TimelineIcon />,
                    path: '/halving-returns'
                },
            ]
        },
        {
            title: 'World Data',
            items: [
                { 
                    id: 'economic-indicators', 
                    label: 'Economic Indicators', 
                    icon: <MonetizationOnIcon />, 
                    path: '/economic-indicators'
                },
            ]
        }
    ]
};

export const aiNavbarOptions = {
    title: 'AI Assistant',
    sections: [
        {
            title: 'Conversations',
            items: [
                {
                    id: 'new-chat',
                    label: 'New Chat',
                    icon: <ChatIcon />,
                    path: '/ai/chat/new'
                }
            ]
        },
        {
            title: 'History',
            items: []  // This will be populated dynamically with chat history
        }
    ]
};

// You can add more navbar options for different sections here