import React, { useState } from 'react';
import { Box, Typography, Button, Switch, FormControlLabel } from '@mui/material';
import { motion } from 'framer-motion';
import StarIcon from '@mui/icons-material/Star';
import styled from '@emotion/styled';
import MetaTags from '../../components/MetaTags';

const PricingCard = styled(motion.div)(({ theme, featured }) => ({
    backgroundColor: featured ? 'rgba(25, 118, 210, 0.12)' : 'rgba(255, 255, 255, 0.03)',
    borderRadius: '16px',
    padding: '32px',
    position: 'relative',
    border: featured ? '2px solid #1976d2' : '1px solid rgba(255, 255, 255, 0.1)',
    transition: 'all 0.3s ease-in-out',
    display: 'flex',
    flexDirection: 'column',
    height: '540px',
    '&:hover': {
        transform: 'translateY(-8px)',
        backgroundColor: featured ? 'rgba(25, 118, 210, 0.15)' : 'rgba(255, 255, 255, 0.05)',
        boxShadow: '0 8px 24px rgba(0, 0, 0, 0.2)'
    }
}));

const PricingPage = () => {
    const [isYearly, setIsYearly] = useState(true);

    const plans = [
        {
            title: 'Basic',
            monthlyPrice: 0,
            yearlyPrice: 0,
            yearlyTotal: 0,
            yearlyDiscount: '0%',
            features: [
                'Access to Basic Dashboard Features',
                '1 Notification System',
                '5 AI Messages per Day',
                'Basic Analytics',
                'Community Support'
            ]
        },
        {
            title: 'Pro',
            monthlyPrice: 5,
            yearlyPrice: 4,
            yearlyTotal: 48,
            yearlyDiscount: '20%',
            featured: true,
            features: [
                'All Basic Features',
                '5 Notification Systems',
                '30 AI Messages per Day',
                'Advanced Analytics',
                'Priority Support',
                'Custom Alerts'
            ]
        },
        {
            title: 'Premium',
            monthlyPrice: 20,
            yearlyPrice: 15,
            yearlyTotal: 180,
            yearlyDiscount: '25%',
            features: [
                'All Pro Features',
                'Unlimited Notifications',
                'Unlimited AI Messages',
                'Enterprise Analytics',
                'Dedicated Support',
                'Early Access to New Features',
                'API Integration Support'
            ]
        }
    ];

    return (
        <>
            <MetaTags 
                title="Pricing" 
                description="Explore SoozHub's flexible pricing plans for cryptocurrency analytics and algorithmic trading. Choose from Basic, Pro, and Premium options to match your investment needs."
            />
            <Box sx={{ 
                minHeight: '100vh',
                bgcolor: 'background.default',
                background: 'linear-gradient(135deg, #0A1929 0%, #1A2027 100%)',
                pt: 12,
                px: 3
            }}>
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                    <Typography
                        variant="h3"
                        sx={{
                            color: '#1976d2',
                            mb: 2,
                            fontWeight: 'bold',
                            background: 'linear-gradient(45deg, #1976d2 30%, #64b5f6 90%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        Choose Your Plan
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            color: 'rgba(255, 255, 255, 0.7)',
                            maxWidth: '600px',
                            mx: 'auto',
                            mb: 4
                        }}
                    >
                        Select the perfect plan to enhance your investment journey with SoozHub
                    </Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isYearly}
                                onChange={(e) => setIsYearly(e.target.checked)}
                                color="primary"
                            />
                        }
                        label={
                            <Typography 
                                variant="body1" 
                                sx={{ 
                                    color: 'text.primary',
                                    width: '180px',
                                    display: 'inline-block',
                                    textAlign: 'left'
                                }}
                            >
                                {isYearly ? 'Yearly (Save up to 25%)' : 'Monthly'}
                            </Typography>
                        }
                        sx={{ mb: 2 }}
                    />
                </Box>

                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: {
                            xs: '1fr',
                            md: 'repeat(3, 1fr)'
                        },
                        gap: 4,
                        maxWidth: '1200px',
                        mx: 'auto'
                    }}
                >
                    {plans.map((plan, index) => (
                        <PricingCard
                            key={plan.title}
                            featured={plan.featured}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: index * 0.2 }}
                        >
                            <Box sx={{ flex: 1 }}>
                                {plan.featured && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: -2,
                                            right: 24,
                                            bgcolor: '#1976d2',
                                            color: 'white',
                                            px: 2,
                                            py: 0.5,
                                            borderRadius: '0 0 8px 8px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 0.5,
                                            zIndex: 1
                                        }}
                                    >
                                        <StarIcon fontSize="small" />
                                        <Typography variant="body2" fontWeight="bold">
                                            Most Popular
                                        </Typography>
                                    </Box>
                                )}
                                <Typography
                                    variant="h5"
                                    sx={{
                                        color: plan.featured ? '#1976d2' : 'rgba(255, 255, 255, 0.9)',
                                        mb: 2,
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {plan.title}
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'flex-end', mb: 1 }}>
                                    <Typography
                                        variant="h3"
                                        component="span"
                                        sx={{
                                            fontWeight: 'bold',
                                            color: 'text.primary',
                                            background: plan.featured ? 'linear-gradient(45deg, #1976d2 30%, #64b5f6 90%)' : 'inherit',
                                            WebkitBackgroundClip: plan.featured ? 'text' : 'none',
                                            WebkitTextFillColor: plan.featured ? 'transparent' : 'inherit'
                                        }}
                                    >
                                        ${isYearly ? plan.yearlyPrice : plan.monthlyPrice}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        component="span"
                                        sx={{
                                            color: 'text.secondary',
                                            ml: 1
                                        }}
                                    >
                                        /mo
                                    </Typography>
                                </Box>
                                <Box sx={{ height: '52px', mb: 3 }}>
                                    {isYearly ? (
                                        <>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    color: '#1976d2',
                                                    mb: 1
                                                }}
                                            >
                                                {plan.yearlyDiscount !== '0%' && `Save ${plan.yearlyDiscount} with yearly billing`}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    color: 'text.secondary'
                                                }}
                                            >
                                                ${plan.yearlyTotal}/year
                                            </Typography>
                                        </>
                                    ) : (
                                        <Box sx={{ height: '52px' }} />
                                    )}
                                </Box>
                                <Box>
                                    {plan.features.map((feature, index) => (
                                        <Typography
                                            key={index}
                                            variant="body1"
                                            sx={{
                                                color: 'text.secondary',
                                                mb: 1,
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Box
                                                component="span"
                                                sx={{
                                                    width: 6,
                                                    height: 6,
                                                    borderRadius: '50%',
                                                    bgcolor: '#1976d2',
                                                    mr: 2
                                                }}
                                            />
                                            {feature}
                                        </Typography>
                                    ))}
                                </Box>
                            </Box>
                            <Button
                                variant={plan.featured ? 'contained' : 'outlined'}
                                color="primary"
                                fullWidth
                                sx={{
                                    py: 1.5,
                                    textTransform: 'none',
                                    fontSize: '1.1rem',
                                    mt: 2,
                                    background: plan.featured ? 'linear-gradient(45deg, #1976d2 30%, #64b5f6 90%)' : 'transparent',
                                    '&:hover': {
                                        background: plan.featured ? 'linear-gradient(45deg, #1565c0 30%, #42a5f5 90%)' : 'rgba(25, 118, 210, 0.08)'
                                    }
                                }}
                            >
                                Get Started
                            </Button>
                        </PricingCard>
                    ))}
                </Box>
            </Box>
        </>
    );
};

export default PricingPage; 