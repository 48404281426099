import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    CardActionArea,
    Skeleton,
    Alert
} from '@mui/material';
import { useAuth } from '../../AuthContext';
import axios from 'axios';
import MetaTags from '../../components/MetaTags';

const API_URL = process.env.REACT_APP_API_URL;

const EconomicIndicators = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { token } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(
                    `${API_URL}/dashboard/economic-indicators/categories`
                );
                setCategories(response.data);
                setError(null);
            } catch (err) {
                console.error('Error fetching economic indicators:', err);
                setError('Failed to load economic indicators');
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    const handleCategoryClick = (tag) => {
        navigate(`/economic-indicators/${tag}`);
    };

    if (loading) {
        return (
            <Box sx={{ p: 3 }}>
                <Grid container spacing={3}>
                    {[1, 2, 3, 4].map((n) => (
                        <Grid item xs={12} sm={6} md={4} key={n}>
                            <Skeleton variant="rectangular" height={200} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 3 }}>
                <Alert severity="error">{error}</Alert>
            </Box>
        );
    }

    return (
        <>
            <MetaTags 
                title="Economic Indicators" 
                description="Track key economic indicators and their impact on cryptocurrency markets with SoozHub's comprehensive data analytics and trend visualization tools."
            />
            <Box sx={{ p: 3 }}>
                <Typography variant="h4" gutterBottom>
                    Economic Indicators
                </Typography>
                <Grid container spacing={3}>
                    {categories.map((category) => (
                        <Grid item xs={12} sm={6} md={4} key={category.id}>
                            <Card>
                                <CardActionArea onClick={() => handleCategoryClick(category.tag)}>
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom>
                                            {category.title}
                                        </Typography>
                                        <Typography color="textSecondary" gutterBottom>
                                            Frequency: {category.frequency}
                                        </Typography>
                                        <Typography color="textSecondary" gutterBottom>
                                            Units: {category.units}
                                        </Typography>
                                        <Typography variant="caption" display="block">
                                            Last updated: {new Date(category.last_updated).toLocaleString()}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
};

export default EconomicIndicators; 