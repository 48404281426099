import React from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MarketData from '../components/graphs/MarketData/MarketData';
import MetaTags from '../components/MetaTags';

const API_URL = process.env.REACT_APP_API_URL;

const WelcomePage = ({ setNavbarOptions }) => {
    const navigate = useNavigate();

    const features = [
        {
            title: "Sentiment Analysis",
            description: "Track cryptocurrency sentiment across social media platforms",
            path: "/sentiment"
        },
        {
            title: "AI Inferencing",
            description: "Interact with advanced AI models for market insights",
            path: "/ai"
        },
        {
            title: "Algo Trading",
            description: "Develop and test algorithmic trading strategies",
            path: "/algo-trading-bots"
        },
        {
            title: "NFT Analytics",
            description: "Monitor NFT market trends and valuations",
            path: "/nfts"
        },
        {
            title: "Economic Indicators",
            description: "Track key economic metrics and their impact",
            path: "/economic-indicators"
        },
        {
            title: "Bitcoin Halving",
            description: "Analyze historical Bitcoin halving cycles",
            path: "/halving-returns"
        }
    ];

    return (
        <>
            <MetaTags 
                title="Dashboard" 
                description="Access your cryptocurrency dashboard with real-time market data, analytics tools, and investment insights on SoozHub."
            />
            <Box sx={{ p: 4 }}>
                {/* Market Data Section */}
                <Box sx={{ mb: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        Market Overview
                    </Typography>
                    <Paper sx={{ p: 2, bgcolor: 'background.paper' }}>
                        <MarketData apiUrl={API_URL} setNavbarOptions={setNavbarOptions} />
                    </Paper>
                </Box>

                {/* Features Grid Section */}
                <Box sx={{ mb: 4 }}>
                    <Typography variant="h4" gutterBottom>
                        Analytics & Tools
                    </Typography>
                    <Grid container spacing={3}>
                        {features.map((feature, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Paper
                                    sx={{
                                        p: 3,
                                        height: '100%',
                                        cursor: 'pointer',
                                        transition: 'transform 0.2s, box-shadow 0.2s',
                                        '&:hover': {
                                            transform: 'translateY(-4px)',
                                            boxShadow: (theme) => theme.shadows[6],
                                            bgcolor: 'rgba(255, 255, 255, 0.03)'
                                        }
                                    }}
                                    onClick={() => navigate(feature.path)}
                                >
                                    <Typography variant="h6" gutterBottom>
                                        {feature.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {feature.description}
                                    </Typography>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default WelcomePage; 