import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Box, Menu, MenuItem, useMediaQuery, useTheme, Tabs, Tab } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useAuth } from '../AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from '../assets/icon.png';

export default function ButtonAppBar() {
    const { user, logout, isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const menuOpen = Boolean(menuAnchorEl);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // Navigation items
    const navItems = [
        { label: 'Dashboard', path: '/dashboard' },
        { label: 'Sentiment', path: '/sentiment' },
        { label: 'AI Inferencing', path: '/ai' },
        { label: 'Algo Trading', path: '/algo-trading-bots' },
        { label: 'NFTs', path: '/nfts' },
        { label: 'Economic', path: '/economic-indicators' }
    ];

    const handleMenu = (event) => {
        if (isAuthenticated()) {
            setAnchorEl(event.currentTarget);
        } else {
            navigate('/login');
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuOpen = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleProfile = () => {
        navigate('/profile');
        handleClose();
    };

    const handleLogout = () => {
        logout();
        handleClose();
        navigate('/dashboard');
    };

    const handleNavItemClick = (path) => {
        if (path === '/ai' || path === '/algo-trading-bots') {
            if (isAuthenticated()) {
                navigate(path);
            } else {
                navigate('/login', { state: { from: path } });
            }
        } else {
            navigate(path);
        }
    };

    return (
        <AppBar position="static">
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                {/* Left side with logo and brand name */}
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    mr: 2
                }}>
                    <img 
                        src={Logo} 
                        alt="SoozHub Logo" 
                        style={{ marginRight: 8, height: '40px', cursor: 'pointer' }} 
                        onClick={() => navigate('/')} 
                    />
                    <Typography 
                        variant="h6" 
                        component="div" 
                        sx={{ 
                            cursor: 'pointer',
                            display: { xs: 'none', sm: 'block' }
                        }} 
                        onClick={() => navigate('/')}
                    >
                        SoozHub
                    </Typography>
                </Box>
                
                {/* Empty space in the middle */}
                <Box sx={{ flexGrow: 1 }} />
                
                {/* Right side account menu */}
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mr: isDesktop ? 0 : 1
                }}>
                    <IconButton
                        size="small"
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        aria-controls={menuOpen ? 'menu-appbar-hamburger' : undefined}
                        aria-haspopup="true"
                        onClick={handleMenuOpen}
                        sx={{ mr: 0.5 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="body2" sx={{ mx: 0.5, color: 'inherit' }}>
                        |
                    </Typography>
                    <IconButton
                        size="small"
                        edge="end"
                        color="inherit"
                        aria-label="account of current user"
                        aria-controls={open ? 'menu-appbar' : undefined}
                        aria-haspopup="true"
                        onClick={handleMenu}
                        sx={{ ml: 0.5 }}
                    >
                        {isAuthenticated() && user?.profilePicture ? (
                            <img 
                                src={user.profilePicture} 
                                alt="Profile" 
                                style={{ borderRadius: '50%', width: '24px', height: '24px' }} 
                            />
                        ) : (
                            <AccountCircle />
                        )}
                    </IconButton>
                    {isAuthenticated() && (
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleProfile}>Profile</MenuItem>
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    )}
                    <Menu
                        id="menu-appbar-hamburger"
                        anchorEl={menuAnchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={menuOpen}
                        onClose={handleMenuClose}
                    >
                        {navItems.map((item, index) => (
                            <MenuItem 
                                key={index}
                                onClick={() => {
                                    handleNavItemClick(item.path);
                                    handleMenuClose();
                                }}
                            >
                                {item.label}
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </Toolbar>
            
            {/* Mobile navigation menu - only shown on mobile */}
            {isMobile && (
                <Box sx={{ 
                    width: '100%', 
                    bgcolor: 'background.paper', 
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                }}>
                    <Tabs
                        value={navItems.findIndex(item => location.pathname.startsWith(item.path)) >= 0 ? 
                            navItems.findIndex(item => location.pathname.startsWith(item.path)) : false}
                        variant="scrollable"
                        scrollButtons="auto"
                        textColor="inherit"
                        indicatorColor="secondary"
                        sx={{
                            minHeight: '36px', // Smaller height for the tabs
                            '& .MuiTab-root': {
                                minHeight: '36px', // Smaller height for individual tabs
                                fontSize: '0.75rem', // Smaller font size
                                textTransform: 'none', // No all-caps
                                padding: '6px 12px' // Less padding
                            }
                        }}
                    >
                        {navItems.map((item, index) => (
                            <Tab 
                                key={index}
                                label={item.label}
                                onClick={() => handleNavItemClick(item.path)}
                            />
                        ))}
                    </Tabs>
                </Box>
            )}
        </AppBar>
    );
}