import React, { useState, useEffect } from 'react';
import { Box, Typography, ThemeProvider, useMediaQuery } from '@mui/material';
import darkTheme from '../../../themes/darkTheme';
import ControlPanel from './ControlPanel';
import SentimentGraph from './SentimentGraph';
import useSentimentData from './useSentimentData';
import { StyledBox } from './styles';
import MetaTags from '../../../components/MetaTags';

const RedditSentiment = ({ apiUrl }) => {
    const [currentFrame, setCurrentFrame] = useState('3M');
    const [selectedSymbol, setSelectedSymbol] = useState('BTC');
    const [selectedSubreddit, setSelectedSubreddit] = useState('overall');
    const [movingAverageDays, setMovingAverageDays] = useState(7);

    const { symbols, data, loading, error } = useSentimentData(apiUrl, currentFrame, selectedSymbol, selectedSubreddit, movingAverageDays);

    const isMobile = useMediaQuery(darkTheme.breakpoints.down('sm'));

    return (
        <>
            <MetaTags 
                title="Reddit Sentiment Analysis" 
                description="Track cryptocurrency sentiment across Reddit communities with SoozHub's sentiment analysis tool. Visualize sentiment trends over time for Bitcoin and other cryptocurrencies."
            />
            <ThemeProvider theme={darkTheme}>
                <StyledBox>
                    <Typography variant="h5" component="h1" gutterBottom>
                        Reddit Cryptocurrency Sentiment
                    </Typography>
                    
                    <ControlPanel 
                        symbols={symbols}
                        selectedSymbol={selectedSymbol}
                        setSelectedSymbol={setSelectedSymbol}
                        currentFrame={currentFrame}
                        setCurrentFrame={setCurrentFrame}
                        movingAverageDays={movingAverageDays}
                        setMovingAverageDays={setMovingAverageDays}
                        isMobile={isMobile}
                    />

                    <SentimentGraph 
                        data={data}
                        movingAverageDays={movingAverageDays}
                        isMobile={isMobile}
                    />
                </StyledBox>
            </ThemeProvider>
        </>
    );
};

export default RedditSentiment;