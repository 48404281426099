import React from 'react';
import { Box, ToggleButton, ToggleButtonGroup, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const ControlPanel = ({ timeframe, setTimeframe, symbol, setSymbol }) => {
    const handleTimeframeChange = (event, newTimeframe) => {
        if (newTimeframe !== null) {
            setTimeframe(newTimeframe);
        }
    };

    const handleSymbolChange = (event) => {
        setSymbol(event.target.value);
    };

    return (
        <Box sx={{ mb: 3, display: 'flex', gap: 2, alignItems: 'center' }}>
            <ToggleButtonGroup
                value={timeframe}
                exclusive
                onChange={handleTimeframeChange}
                aria-label="timeframe"
                size="small"
            >
                <ToggleButton value="1d">1D</ToggleButton>
                <ToggleButton value="7d">7D</ToggleButton>
                <ToggleButton value="1m">1M</ToggleButton>
                <ToggleButton value="3m">3M</ToggleButton>
                <ToggleButton value="6m">6M</ToggleButton>
                <ToggleButton value="1y">1Y</ToggleButton>
                <ToggleButton value="all">ALL</ToggleButton>
            </ToggleButtonGroup>

            <FormControl sx={{ minWidth: 120 }} size="small">
                <InputLabel id="symbol-select-label">Symbol</InputLabel>
                <Select
                    labelId="symbol-select-label"
                    id="symbol-select"
                    value={symbol}
                    label="Symbol"
                    onChange={handleSymbolChange}
                >
                    <MenuItem value="BTC">BTC</MenuItem>
                    <MenuItem value="ETH">ETH</MenuItem>
                    <MenuItem value="SOL">SOL</MenuItem>
                    <MenuItem value="XRP">XRP</MenuItem>
                    <MenuItem value="ADA">ADA</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

export default ControlPanel; 